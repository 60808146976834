%font-regular {
  /* Book Weight */
  font-family: itc-avant-garde-gothic-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
}

%font-medium {
  /* Medium Weight */
  font-family: itc-avant-garde-gothic-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
}

%font-bold {
  /* Bold Weight */
  font-family: itc-avant-garde-gothic-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
}