@import 'colours';

.Languages {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  overflow: hidden;
  pointer-events: none;

  ul {
    width: 100%;
    list-style: none;
    padding: 0;
    text-align: center;

    li {
      display: inline-block;
    }
  }

  &__menu {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 0;
    margin: -20px 0 0 0;
    transition: all 0.250s ease-out;

    li {
      margin: 0 30px;
      position: relative;
      transition: all 0.250s ease-out;

      &::before {
        content: '';
        position: absolute;
        bottom: -10px;
        left: calc(50% - 10px);
        width: 20px;
        height: 1px;
        background-color: $white;
        // transform: scaleX(0);
        transition: all 0.250s ease-out;
      }
    }
  }

  &__footer {
    will-change: transform, opacity;
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    color: $black;
    text-transform: uppercase;
    position: absolute;
    bottom: 10px;
    left: 0;

    li {
      position: relative;
      will-change: border-color;
      border: 1px solid rgba($black, 0);
      padding: 7px 8px 11px 8px;
      margin: 0 10px;
      transition: border-color 0.250s;
      line-height: 1;

      &::before {
        content: '';
        position: absolute;
        bottom: 9px;
        left: calc(50% - 10px);
        width: 20px;
        height: 1px;
        background-color: $black;
      }

      &.is-active {
        border-color: rgba($black, 1);
      }
    }
  }

  // Scenes for the menu
  &.scene-landing &__menu {
    transform: translateY(100%);
    opacity: 0;
    pointer-events: none;
  }

  &.scene-languages &__menu {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  }

  &.scene-languages.frame-0 &__menu {
    transform: translateY(100%);
    opacity: 0;
  }

  &.scene-languages.frame-1 &__menu {
    transform: translateY(0%);
    opacity: 1;
    pointer-events: all;
  }

  &.scene-languages.frame-2 &__menu {
    transform: translateY(0%);
    opacity: 1;
    transition: all 0.250s ease-out;
    pointer-events: all;
  }

  &.scene-languages.frame-2 &__menu li::before,
  &.scene-languages.frame-3 &__menu li::before {
    transform: scaleX(1);
    transition: all 0.250s ease-out;
  }

  &.scene-languages.frame-3 &__menu li:not(.is-active),
  &.scene-languages.frame-4 &__menu li:not(.is-active) {
    opacity: 0;
    pointer-events: none;
    transition: all 0.250s ease-out;
  }

  &.scene-languages.frame-3 &__menu li:nth-child(1).is-active {
    transform: translateX(95%);
    transition: all 0.250s ease-out;
    // opacity: 1;
    pointer-events: none;
  }

  &.scene-languages.frame-3 &__menu li:nth-child(2).is-active {
    transform: translateX(-70%);
    transition: all 0.250s ease-out;
    // opacity: 1;
    pointer-events: none;
  }

  &.scene-languages.frame-4 &__menu li:nth-child(1).is-active {
    transform: translateX(95%);
    opacity: 0;
    pointer-events: none;
    transition: all 0.250s ease-out;
  }

  &.scene-languages.frame-4 &__menu li:nth-child(2).is-active {
    transform: translateX(-70%);
    opacity: 0;
    pointer-events: none;
    transition: all 0.250s ease-out;
  }
  
  &.scene-languages.frame-4 &__menu {
    opacity: 0;
    pointer-events: none;
    transition: all 0.250s ease-out;
  }

  &.scene-menu &__menu {
    opacity: 0;
    pointer-events: none;
  }

  // Scenes for the footer
  &.scene-landing &__footer {
    opacity: 0;
    transform: translateY(100%);
    pointer-events: none;
  }

  &.scene-languages &__footer {
    opacity: 0;
    transform: translateY(100%);
    pointer-events: none;
  }

  &.scene-menu &__footer {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }
}