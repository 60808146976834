@import 'colours';

.Menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;

  &__product {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    margin-top: 100px;
    transition: transform 0.250s ease-out, opacity 0.250s ease-out;
  }

  &__nav {
    list-style: none;
    text-transform: uppercase;
    margin: 60px 0 0 0;
    padding: 0 0 0 40px;
    transition: transform 0.250s ease-out, opacity 0.250s ease-out;

    li {
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.75px;
      margin-bottom: 50px;
      transition: transform 0.250s ease-out, opacity 0.250s ease-out;
      display: block;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 20px;
        height: 1px;
        background-color: $white;
      }

      &:nth-child(1) {
        transition-delay: 0.1s;
      }
      
      &:nth-child(2) {
        transition-delay: 0.2s;
      }

      &:nth-child(3) {
        transition-delay: 0.3s;
      }

      &:nth-child(4) {
        transition-delay: 0.4s;
      }

      a {
        text-decoration: none;
        color: $white;
      }
    }
  }

  &.scene-landing &__product {
    transform: translateY(100%);
    opacity: 0;
    pointer-events: none;
  }

  &.scene-languages &__product {
    transform: translateY(100%);
    opacity: 0;
    pointer-events: none;
  }

  &.scene-menu &__product {
    transform: translateY(0);
    opacity: 1;
  }

  &.scene-landing &__nav,
  &.scene-language &__nav {
    pointer-events: none;
  }

  &.scene-menu &__nav {
    pointer-events: all;
  }

  &.scene-menu.frame-0 &__product {
    transform: translateY(100%);
    opacity: 0;
  }

  &.scene-menu.frame-1 &__product {
    transform: translateY(0%);
    opacity: 1;
  }
  

  &.scene-landing &__nav li,
  &.scene-languages &__nav li,
  &.scene-menu &__nav li,
  &.scene-menu.frame-0 &__nav li {
    transform: translateX(-100%);
    opacity: 0;
  }

  &.scene-menu.frame-1 &__nav li {
    transform: translateX(0%);
    opacity: 1;
  }
}