.Landing {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  z-index: 5;
  text-align: center;
  text-transform: uppercase;
  pointer-events: none;

  &__welcome,
  &__welcome--fr {
    will-change: opacity, transform;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px 0;
    opacity: 1;
    transition: transform 0.250s ease-out, opacity 0.250s ease-out;

    &--fr {
      opacity: 0;
      margin-top: -40px;
    }
  }

  &__product {
    font-size: 18px;
    font-weight: 300;
    margin: 0;
    transition: transform 0.250s ease-out, opacity 0.250s ease-out;
  }

  // Landing scene
  &.scene-landing.frame-0 &__welcome,
  &.scene-landing.frame-0 &__product {
    opacity: 0;
    transform: translateY(100%);
  }

  &.scene-landing.frame-1 &__welcome,
  &.scene-landing.frame-1 &__product {
    opacity: 1;
    transform: translateY(0);
  }

  &.scene-landing.frame-2 &__welcome,
  &.scene-landing.frame-2 &__product {
    opacity: 1;
    transform: translateY(0);
  }

  &.scene-landing.frame-2 &__welcome {
    opacity: 0;
    transform: translateY(-100%);
  }

  &.scene-landing.frame-2 &__welcome--fr {
    opacity: 1;
    transform: translateY(0%);
  }

  &.scene-landing.frame-3 &__welcome,
  &.scene-landing.frame-3 &__welcome--fr {
    opacity: 0;
    transform: translateY(-150%);
  }

  &.scene-landing.frame-3 &__product {
    opacity: 0;
    transform: translateY(-100%);
  }

  // Languages and Menu scenes
  &.scene-languages &__welcome,
  &.scene-menu &__welcome {
    opacity: 0;
  }

  .scene-languages &__product,
  .scene-menu &__product {
    opacity: 0;
  }
}