.Logo {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  transition: all 0.500s ease-out;
  pointer-events: none;

  &__image {
    width: 132px;
    transition: all 0.500s ease-out;
    opacity: 1;
  }

  &.scene-landing.frame-0 &__image {
    opacity: 0;
  }

  &.scene-landing.frame-1 &__image {
    opacity: 1;
  }

  // &.scene-languages &__image {}

  &.scene-menu {
    bottom: calc(100% - 90px);
  }

  &.scene-menu &__image {
    width: 98px;
  }
}