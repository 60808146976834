.Video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 10;

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    font-size: 16px;
  }

  &__embed {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}