@import 'colours';

.Background {
  position: relative;
  width: 100%;
  height: 100%;

  &__bottle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/bottle.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
    transition: transform 0.600s ease-out;
  }

  &.scene-landing &__bottle {
    transform: translateX(-100%);
  }

  &.scene-languages &__bottle {
    transform: translateX(0%);
  }

  &.scene-menu &__bottle {
    transform: translateX(40%);
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 0;
  }
}